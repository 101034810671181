<template>
    <div class="title-bar">
        <div class="left">
            <Button type="primary" class="return" @click="$router.go(-1)">
                <i class="nazaio-iconfont nz-arrow-left" />
                返回
            </Button>
            <h2><slot /></h2>
        </div>
    </div>
</template>
<script>
export default {};
</script>
<style lang="less" scoped>
.title-bar {
    position: absolute;
    height: 48px;
    left: 0;
    top: 0;
    width: 100%;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    background-color: #fff;
    z-index: 1001;

    .left {
        float: left;
    }

    h2 {
        float: left;
        font-size: 16px;
        color: #0a2a4c;
        font-weight: 400;
        margin-left: 10px;
        height: 48px;
        line-height: 48px;
    }
}
</style>
<style lang="less">
.return {
    margin: 8px 0 8px 20px;
    float: left;
}
</style>