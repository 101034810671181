<template>
    <div>
        <TitleBar>知识货架报表</TitleBar>
        <div class="main-container">
            <div class="report-layout">
                <div class="left-panel">
                    <div class="filters">
                        <div class="filter">
                            <label>选择部门</label>
                            <SelectTree
                                v-model="leftQuery.departmentId"
                                style="width: 319px"
                                :treeData="departmentTree"
                                clearable
                                @on-change="onDepartmentChange"
                            ></SelectTree>
                        </div>
                        <div class="filter">
                            <label>筛选日期</label>
                            <DatePicker
                                style="width: 319px"
                                type="daterange"
                                format="yyyy-MM-dd"
                                placeholder="请选择日期"
                                v-model="timeRangeLeft"
                                @on-change="onLeftDateChange"
                            ></DatePicker>
                        </div>
                    </div>
                    <div class="summary-info">
                        <div class="summary-header">
                            <h2>所有来源</h2>
                            <Dropdown trigger="click" class="drop-down" @on-click="changeSort">
                                <a href="javascript:void(0)">
                                    <i class="nazaio-iconfont nz-sort" />
                                    <span class="sort-desc">{{ orderTypes[leftQuery.orderKey] }}</span>
                                    <Icon type="ios-arrow-down"></Icon>
                                </a>
                                <DropdownMenu slot="list">
                                    <DropdownItem :selected="leftQuery.orderKey === 'viewCount'" name="viewCount"
                                        >按照访问量排序</DropdownItem
                                    >
                                    <DropdownItem :selected="leftQuery.orderKey === 'favoriteCount'" name="favoriteCount"
                                        >按照收藏量排序</DropdownItem
                                    >
                                    <DropdownItem :selected="leftQuery.orderKey === 'shareCount'" name="shareCount"
                                        >按照转发量排序</DropdownItem
                                    >
                                    <DropdownItem :selected="leftQuery.orderKey === 'downloadCount'" name="downloadCount"
                                        >按照下载量排序</DropdownItem
                                    >
                                </DropdownMenu>
                            </Dropdown>
                        </div>
                        <div class="summary-amount">
                            <div class="amount-item">
                                <i class="nazaio-iconfont nz-eye" />
                                <h3>{{ summaryInfo.viewCount }}</h3>
                            </div>
                            <div class="amount-item">
                                <i class="nazaio-iconfont nz-star" />
                                <h3>{{ summaryInfo.favoriteCount }}</h3>
                            </div>
                            <div class="amount-item">
                                <i class="nazaio-iconfont nz-share-forward" />
                                <h3>{{ summaryInfo.shareCount }}</h3>
                            </div>
                            <div class="amount-item">
                                <i class="nazaio-iconfont nz-download" />
                                <h3>{{ summaryInfo.downloadCount }}</h3>
                            </div>
                        </div>
                    </div>
                    <ul class="employee-list">
                        <li
                            v-for="(employee, index) in employeeList"
                            :key="employee.employeeId"
                            :class="{ active: activeEmployeeIndex === index }"
                            @click="clickEmployee(index)"
                        >
                            <div class="avatar">
                                <img v-if="employee.employeeId > 0" :src="employee.employeeAvatar" />
                                <i v-else class="nazaio-iconfont nz-clients" />
                            </div>
                            <div class="employee-info">
                                <h2>
                                    来源于：<span>{{ employee.employeeName }}</span>
                                </h2>
                                <div class="summary-amount">
                                    <div class="amount-item">
                                        <i class="nazaio-iconfont nz-eye" />
                                        <h3>{{ employee.viewCount }}</h3>
                                    </div>
                                    <div class="amount-item">
                                        <i class="nazaio-iconfont nz-star" />
                                        <h3>{{ employee.favoriteCount }}</h3>
                                    </div>
                                    <div class="amount-item">
                                        <i class="nazaio-iconfont nz-share-forward" />
                                        <h3>{{ employee.shareCount }}</h3>
                                    </div>
                                    <div class="amount-item">
                                        <i class="nazaio-iconfont nz-download" />
                                        <h3>{{ employee.downloadCount }}</h3>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
                <div class="right-panel">
                    <h1>
                        {{
                            activeEmployeeIndex != undefined && employeeList.length > activeEmployeeIndex
                                ? employeeList[activeEmployeeIndex].employeeName
                                : "请选择来源"
                        }}
                    </h1>
                    <div class="summary-items">
                        <div class="summary-item">
                            <div class="left">
                                <h2>访问量</h2>
                                <h3>
                                    {{
                                        activeEmployeeIndex != undefined && employeeList.length > activeEmployeeIndex
                                            ? employeeList[activeEmployeeIndex].viewCount
                                            : summaryInfo.viewCount
                                    }}
                                </h3>
                            </div>
                            <i class="nazaio-iconfont nz-eye" />
                        </div>
                        <div class="summary-item">
                            <div class="left">
                                <h2>收藏量</h2>
                                <h3>
                                    {{
                                        activeEmployeeIndex != undefined && employeeList.length > activeEmployeeIndex
                                            ? employeeList[activeEmployeeIndex].favoriteCount
                                            : summaryInfo.favoriteCount
                                    }}
                                </h3>
                            </div>
                            <i class="nazaio-iconfont nz-star" />
                        </div>
                        <div class="summary-item">
                            <div class="left">
                                <h2>转发量</h2>
                                <h3>
                                    {{
                                        activeEmployeeIndex != undefined && employeeList.length > activeEmployeeIndex
                                            ? employeeList[activeEmployeeIndex].shareCount
                                            : summaryInfo.shareCount
                                    }}
                                </h3>
                            </div>
                            <i class="nazaio-iconfont nz-share-forward" />
                        </div>
                        <div class="summary-item">
                            <div class="left">
                                <h2>下载量</h2>
                                <h3>
                                    {{
                                        activeEmployeeIndex != undefined && employeeList.length > activeEmployeeIndex
                                            ? employeeList[activeEmployeeIndex].downloadCount
                                            : summaryInfo.downloadCount
                                    }}
                                </h3>
                            </div>
                            <i class="nazaio-iconfont nz-download" />
                        </div>
                    </div>
                    <div class="data-box">
                        <div class="filter">
                            <h2>客户行为</h2>
                            <div class="right">
                                <label>筛选日期</label>
                                <DatePicker
                                    type="daterange"
                                    format="yyyy-MM-dd"
                                    placeholder="请选择日期"
                                    v-model="timeRangeRight"
                                    style="width: 319px"
                                    placement="bottom-end"
                                    @on-change="onRightDateChange"
                                ></DatePicker>
                            </div>
                        </div>
                        <ul class="date-list" v-if="detailList.length > 0">
                            <li v-for="dateItem in detailList" :key="dateItem.date">
                                <div class="date">
                                    <h2>{{ dateItem.date }}</h2>
                                    <hr />
                                </div>
                                <ul class="item-list">
                                    <li v-for="(item, index) in dateItem.items" :key="item.id">
                                        <h2>{{ item.displayTime }}</h2>
                                        <div class="time-item">
                                            <div v-if="index > 0" class="time-line-up"></div>
                                            <div class="time-dot"></div>
                                            <div v-if="index < dateItem.items.length - 1" class="time-line-down"></div>
                                        </div>
                                        <img :src="item.avatar" />
                                        <h3>{{ item.nickname }}</h3>
                                        <h4>{{ item.actionType | actionTypeFilter(item.pageType) }}</h4>
                                        <h5 v-if="item.pageType === 0 && item.pageId === 0">{{ item.knowledgeShelfName }}</h5>
                                        <h5 v-else>{{ item.title }}</h5>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                        <div v-else class="no-data">暂无数据</div>
                        <div class="load-more" v-if="detailList.length > 0">
                            <button v-if="hasMore" @click="getKnowledgeShelfReportDetails">加载更多</button>
                            <span class="load-all" v-else>已显示所有数据</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import TitleBar from "./components/ReportTitleBar";
import { getSummaryInfo, getEmployeeSummaryInfoList, getKnowledgeShelfReportDetails } from "@/api/knowledge-shelf";
import { getList as getDepartmentTree } from "@/api/department";
import SelectTree from "../Employee/components/TreeSelect";

Date.prototype.format = function (fmt) {
    var o = {
        "M+": this.getMonth() + 1, //月份
        "d+": this.getDate(), //日
        "h+": this.getHours(), //小时
        "m+": this.getMinutes(), //分
        "s+": this.getSeconds(), //秒
        "q+": Math.floor((this.getMonth() + 3) / 3), //季度
        S: this.getMilliseconds(), //毫秒
    };
    if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (this.getFullYear() + "").substr(4 - RegExp.$1.length));
    for (var k in o)
        if (new RegExp("(" + k + ")").test(fmt))
            fmt = fmt.replace(RegExp.$1, RegExp.$1.length == 1 ? o[k] : ("00" + o[k]).substr(("" + o[k]).length));
    return fmt;
};

export default {
    components: {
        TitleBar,
        SelectTree,
    },
    created() {
        this.id = this.$route.params.id;
        this.leftQuery.knowledgeShelfId = this.id;
        this.getDepartmentTree();
        this.getSummaryInfo();
        this.getEmployeeSummaryInfoList();
        this.getKnowledgeShelfReportDetails();
    },
    filters: {
        actionTypeFilter(actionType, pageType) {
            let action = "";
            switch (actionType) {
                case 1:
                    action = "浏览";
                    break;
                case 2:
                    action = "收藏";
                    break;
                case 3:
                    action = "分享";
                    break;
                case 4:
                    action = "下载";
                    break;
                default:
                    action = "未知操作";
            }
            let name = "";
            if (pageType === 1) {
                name = "轻页面";
            } else if (pageType === 2) {
                if (actionType === 1) {
                    action = "查看";
                }
                name = "文件";
            } else if (pageType === 3) {
                if (actionType === 1) {
                    action = "查看";
                }
                name = "直播活动";
            } else {
                name = "知识货架";
            }
            return action + name;
        },
        pageTypeFilter(pageType) {
            if (pageType === 1) {
                return "轻页面";
            } else if (pageType === 2) {
                return "文件";
            } else if (pageType === 3) {
                return "直播活动";
            }
            return "未知内容";
        },
    },
    data() {
        return {
            id: 0,
            totalSize: 0,
            departmentTree: [],
            summaryInfo: {
                viewCount: 0,
                favoriteCount: 0,
                shareCount: 0,
                downloadCount: 0,
            },
            employeeList: [],
            activeEmployeeIndex: undefined,
            detailList: [],
            leftQuery: {
                knowledgeShelfId: 0,
                departmentId: 0,
                createTimeStart: "",
                createTimeEnd: "",
                orderKey: "viewCount",
                orderType: "desc",
            },
            orderTypes: {
                viewCount: "按照访问量排序",
                favoriteCount: "按照收藏量排序",
                shareCount: "按照转发量排序",
                downloadCount: "按照下载量排序",
            },
            timeRangeLeft: "",
            rightQuery: {
                employeeId: -1,
                departmentId: 0,
                createTimeStart: "",
                createTimeEnd: "",
                startIndex: 0,
                length: 20,
            },
            timeRangeRight: "",
            hasMore: true,
        };
    },
    methods: {
        getDepartmentTree() {
            getDepartmentTree().then((response) => (this.departmentTree = response));
        },
        getSummaryInfo() {
            getSummaryInfo(this.id, this.leftQuery).then((response) => (this.summaryInfo = response));
        },
        getEmployeeSummaryInfoList() {
            this.activeEmployeeIndex = undefined;
            getEmployeeSummaryInfoList(this.id, this.leftQuery).then((response) => {
                this.employeeList = response;
            });
        },
        getKnowledgeShelfReportDetails() {
            getKnowledgeShelfReportDetails(this.id, this.rightQuery).then((resp) => {
                if (!resp.length) {
                    this.hasMore = false;
                    return;
                }
                resp.forEach((r) => {
                    let index = this.detailList.findIndex((d) => d.date === r.date);
                    if (index >= 0) {
                        this.detailList[index].items = this.detailList[index].items.concat(r.items);
                    } else {
                        this.detailList.push(r);
                    }
                });
                let startIndex = 0;
                this.detailList.forEach((detail) => (startIndex = startIndex + detail.items.length));
                this.rightQuery.startIndex = startIndex;
            });
        },
        changeSort(orderKey) {
            this.leftQuery.orderKey = orderKey;
            this.getEmployeeSummaryInfoList();
        },
        clickEmployee(index) {
            this.activeEmployeeIndex = index;
        },
        onRightDateChange(val) {
            if (val && val.length === 2) {
                this.rightQuery.createTimeStart = val[0];
                this.rightQuery.createTimeEnd = val[1];
            } else {
                this.rightQuery.createTimeStart = "";
                this.rightQuery.createTimeEnd = "";
            }
            this.rightQuery.startIndex = 0;
            this.hasMore = true;
            this.detailList = [];
            this.getKnowledgeShelfReportDetails();
        },
        onLeftDateChange(val) {
            if (val && val.length === 2) {
                this.leftQuery.createTimeStart = val[0];
                this.leftQuery.createTimeEnd = val[1];
            } else {
                this.leftQuery.createTimeStart = "";
                this.leftQuery.createTimeEnd = "";
            }
            this.getSummaryInfo();
            this.getEmployeeSummaryInfoList();
        },
        onDepartmentChange(val) {
            this.leftQuery.departmentId = val;
            this.rightQuery.departmentId = val;
            this.getSummaryInfo();
            this.getEmployeeSummaryInfoList();
            this.timeRangeRight = "";
            this.rightQuery.createTimeStart = "";
            this.rightQuery.createTimeEnd = "";
            this.rightQuery.startIndex = 0;
            this.hasMore = true;
            this.detailList = [];
            this.rightQuery.employeeId = -1;
            this.getKnowledgeShelfReportDetails();
        },
    },
    watch: {
        activeEmployeeIndex(val) {
            this.timeRangeRight = "";
            this.rightQuery.createTimeStart = "";
            this.rightQuery.createTimeEnd = "";
            this.rightQuery.startIndex = 0;
            this.hasMore = true;
            this.detailList = [];
            if (val !== undefined) {
                this.rightQuery.employeeId = this.employeeList[val].employeeId;
                this.getKnowledgeShelfReportDetails();
            } else {
                this.rightQuery.employeeId = -1;
                this.getKnowledgeShelfReportDetails();
            }
        },
    },
};
</script>

<style lang="less" scoped>
.report-layout {
    display: flex;
    height: 100%;
}

.left-panel {
    background-color: #fff;
    flex: 0 0 427px;
    border-radius: 4px;
    display: flex;
    flex-flow: column nowrap;

    .filters {
        padding: 20px;
        flex: 0 0 auto;

        .filter {
            margin-top: 12px;
            display: flex;

            &:first-child {
                margin-top: 0;
            }

            label {
                flex: 0 0 68px;
                height: 32px;
                line-height: 32px;
            }
        }
    }

    .summary-info {
        margin-top: 4px;
        padding: 0 20px 20px 20px;
        border-bottom: 1px solid #e8eaec;
        flex: 0 0 auto;

        .summary-header {
            display: flex;
            justify-content: space-between;

            h2 {
                font-size: 16px;
                margin: 0;
                height: 24px;
                line-height: 24px;
                color: #0a2a4c;
            }

            .nz-sort {
                font-size: 14px;
                margin-right: 8px;
            }

            .sort-desc {
                color: #395069;
                margin-right: 8px;

                & + * {
                    color: #395069;
                }
            }
        }

        .summary-amount {
            margin-top: 16px;
            display: flex;
            justify-content: space-evenly;

            .amount-item {
                text-align: center;
                width: 25%;

                i {
                    width: 32px;
                    height: 32px;
                    display: block;
                    text-align: center;
                    font-size: 16px;
                    color: #fff;
                    padding-top: 4px;
                    border-radius: 16px;
                    margin: 0 auto;
                }

                .nz-eye {
                    background-color: #19be6b;
                }

                .nz-star {
                    background-color: #ff9900;
                }

                .nz-share-forward {
                    background-color: #006df1;
                }

                .nz-download {
                    background-color: #00b2a6;
                }

                h3 {
                    margin-top: 8px;
                    height: 18px;
                    line-height: 18px;
                    font-size: 12px;
                    font-weight: normal;
                    color: #395069;
                    text-align: center;
                }
            }
        }
    }

    .employee-list {
        flex: 1 1 auto;
        list-style: none;
        padding: 10px 20px;
        // height: 100%;
        overflow-y: auto;

        &::-webkit-scrollbar {
            width: 6px;
        }

        &::-webkit-scrollbar-thumb {
            background-color: #d4dbe1;
            border-radius: 3px;
        }

        li {
            padding: 18px 16px;
            display: flex;
            cursor: pointer;

            &.active {
                background-color: #ebf2fa;
            }

            .avatar {
                width: 64px;
                height: 64px;
                border-radius: 2px;
                overflow: hidden;

                img {
                    width: 100%;
                    height: 100%;
                    display: block;
                }

                i {
                    display: block;
                    width: 100%;
                    height: 100%;
                    text-align: center;
                    font-size: 30px;
                    color: #fff;
                    background-color: #8494a6;
                    padding-top: 10px;
                }
            }

            .employee-info {
                flex: 1 1 267px;
                width: 267px;
                margin-left: 20px;

                h2 {
                    font-size: 14px;
                    color: #8494a6;
                    font-weight: normal;
                    margin: 0 0 0 20px;
                    height: 22px;
                    line-height: 22px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;

                    span {
                        color: #0a2a4c;
                    }
                }

                .summary-amount {
                    display: flex;
                    justify-content: space-between;
                    margin-top: 6px;
                }

                .amount-item {
                    text-align: center;
                    width: 25%;

                    i {
                        display: block;
                        text-align: center;
                        font-size: 15px;
                        margin: 0 auto;
                        height: 19px;
                    }

                    .nz-eye {
                        color: #19be6b;
                    }

                    .nz-star {
                        color: #ff9900;
                    }

                    .nz-share-forward {
                        color: #006df1;
                    }

                    .nz-download {
                        color: #00b2a6;
                    }

                    h3 {
                        margin-top: 2px;
                        height: 18px;
                        line-height: 18px;
                        font-size: 12px;
                        font-weight: normal;
                        color: rgba(0, 0, 0, 0.5);
                        text-align: center;
                    }
                }
            }
        }
    }
}

.right-panel {
    flex: 1 1 auto;
    background-color: #fff;
    margin-left: 20px;
    border-radius: 4px;
    padding: 20px 0;
    display: flex;
    flex-flow: column nowrap;
    overflow: hidden;

    h1 {
        flex: 0 0 auto;
        padding: 0 20px;
        font-size: 16px;
        margin: 0;
        height: 24px;
        line-height: 24px;
        color: #0a2a4c;
    }

    .summary-items {
        flex: 0 0 auto;
        margin-top: 16px;
        padding: 0 20px;
        display: flex;

        .summary-item {
            flex: 1 1 auto;
            height: 112px;
            background: linear-gradient(270deg, #fff, #ffffff 50%, #f2f4f7 100%);
            border: 1px solid #e8eaec;
            border-radius: 5px;
            display: flex;
            padding: 24px;
            margin-right: 24px;

            &:last-child {
                margin-right: 0;
            }

            .left {
                flex: 1 1 auto;

                h2 {
                    font-size: 16px;
                    margin: 0;
                    height: 24px;
                    line-height: 24px;
                    color: #8494a6;
                    font-weight: normal;
                }

                h3 {
                    margin-top: 8px;
                    font-size: 24px;
                    height: 32px;
                    line-height: 32px;
                    width: 80px;
                }
            }

            i {
                flex: 0 0 auto;
                width: 64px;
                height: 64px;
                border-radius: 32px;
                font-size: 26px;
                text-align: center;
                padding-top: 12px;
            }

            .nz-eye {
                color: #19be6b;
                background-color: #edfff3;
            }

            .nz-star {
                color: #ff9900;
                background-color: #fff9e6;
            }

            .nz-share-forward {
                color: #006df1;
                background-color: #e6f5ff;
            }

            .nz-download {
                color: #00b2a6;
                background-color: #daf2ed;
            }
        }
    }

    .data-box {
        margin-top: 20px;
        border-top: 1px solid #e8eaec;
        padding: 16px 20px;
        flex: 1 1 auto;
        overflow-y: auto;

        &::-webkit-scrollbar {
            width: 6px;
        }

        &::-webkit-scrollbar-thumb {
            background-color: #d4dbe1;
            border-radius: 3px;
        }

        .filter {
            display: flex;
            justify-content: space-between;

            h2 {
                font-size: 16px;
                margin: 0;
                height: 32px;
                line-height: 32px;
                color: #0a2a4c;
            }

            .right {
                label {
                    line-height: 32px;
                    font-size: 14px;
                    color: #395069;
                    margin-right: 12px;
                }
            }
        }

        .date-list {
            list-style: none;
            padding: 0;
            margin-top: 16px;

            & > li {
                margin-bottom: 21px;

                .date {
                    display: flex;

                    h2 {
                        flex: 0 0 auto;
                        font-size: 14px;
                        margin: 0;
                        height: 22px;
                        line-height: 22px;
                        color: #0a2a4c;
                        margin-right: 12px;
                    }

                    hr {
                        flex: 1 1 auto;
                        margin-top: 10px;
                        background-color: #e8eaec;
                        height: 1px;
                        border: none;
                    }
                }
            }

            .item-list {
                padding-left: 20px;
                list-style: none;
                margin-top: 17px;

                li {
                    display: flex;
                    margin-bottom: 20px;

                    &:last-child {
                        margin-bottom: 0;
                    }
                }

                h2 {
                    flex: 0 0 auto;
                    font-weight: normal;
                    height: 24px;
                    line-height: 24px;
                    margin: 0;
                    font-size: 14px;
                    color: #8494a6;
                    margin-right: 20px;
                }

                .time-item {
                    flex: 0 0 auto;
                    margin-right: 16px;
                    width: 10px;
                    height: 24px;
                    position: relative;

                    .time-line-up {
                        position: absolute;
                        width: 2px;
                        left: 4px;
                        background-color: #daf2ed;
                        height: 17px;
                        bottom: 17px;
                    }

                    .time-dot {
                        width: 10px;
                        height: 10px;
                        background-color: @fc-main-color;
                        border-radius: 5px;
                        position: absolute;
                        left: 50%;
                        margin-left: -5px;
                        top: 50%;
                        margin-top: -5px;
                    }

                    .time-line-down {
                        position: absolute;
                        width: 2px;
                        left: 4px;
                        background-color: #daf2ed;
                        height: 17px;
                        top: 17px;
                    }
                }

                img {
                    flex: 0 0 auto;
                    display: block;
                    width: 24px;
                    height: 24px;
                    border-radius: 12px;
                    margin-right: 12px;
                }

                h3,
                h4 {
                    flex: 0 0 auto;
                    font-size: 14px;
                    font-weight: normal;
                    color: #395069;
                    margin: 0 4px 0 0;
                    height: 24px;
                    line-height: 24px;
                }

                h5 {
                    flex: 1 1 auto;
                    font-size: 14px;
                    font-weight: normal;
                    color: @fc-main-color;
                    margin: 0 4px 0 0;
                    height: 24px;
                    line-height: 24px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
            }
        }

        .no-data {
            text-align: center;
            color: #8494a6;
            font-size: 14px;
        }

        .load-more {
            text-align: center;

            button {
                background-color: transparent;
                border: none;
                outline: none;
                font-size: 14px;
                color: @fc-main-color;
                cursor: pointer;
            }

            .load-all {
                color: #8494a6;
            }
        }
    }
}
</style>